<template>
  <el-form
    :model="form"
    ref="form"
    label-width="100px"
    class="company-ruleForm"
    label-position="top"
    :disabled="submitting"
  >
    <!-- <el-alert
      :closable="false"
      title="Tips：输入完成后敲击回车可直接新增一行"
      type="info"
    >
    </el-alert> -->
    <el-row :gutter="10" v-for="(item, index) in form.items" :key="item.key">
      <el-col :span="10">
        <el-form-item
          :label="`标签名称 ${index + 1}`"
          :prop="'items.' + index + '.name'"
          :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
        >
          <el-input
            :disabled="submitting"
            v-model.trim="item.name"
            clearable
            :ref="`input${index}`"
            placeholder="请输入"
            @keyup.enter.native="addRow(`input${index + 1}`)"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="所属层级" :prop="'items.' + index + '.parentId'">
          <el-select
            v-model="item.parentId"
            placeholder="请选择"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="option in parentOptions"
              :key="option.id + index"
              :label="option.name"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="操作" prop="userCountLimit"
          ><el-button
            @click="onDeleteRow(index)"
            type="danger"
            :disabled="submitting"
            v-if="form.items.length > 1"
            >删除</el-button
          ></el-form-item
        >
      </el-col>
    </el-row>
    <el-form-item style="text-align: right; margin-top: 30px">
      <el-button @click="onCancel" :disabled="submitting">关 闭</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >提交创建</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { MultipleClientTags, GetClientTagsAll } from "../api";
import { ShowApiError } from "@/request/error";
export default {
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      form: {
        items: [
          {
            parentId: null,
            name: "",
            key: this.createUniqueString(),
          },
        ],
      },
      parentOptions: [],
    };
  },
  created() {
    this.getParentData();
  },
  methods: {
    createUniqueString,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datas = [];
          this.form.items.forEach((item) => {
            datas.push({
              name: item.name,
              parentId: item.parentId,
            });
          });
          this.submitting = true;
          MultipleClientTags(datas)
            .then(() => {
              this.submitting = false;
              this.initForm();
              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("批量新增异常", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getParentData() {
      GetClientTagsAll().then((res) => {
        let result = res.data.filter(item => item.parentId === null);
        this.parentOptions = [{ id: null, name: "顶级" }, ...result];
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            parentId: null,
            name: "",
          },
        ],
      };
      this.getParentData();
    },
    addRow(inputObj = "") {
      this.form.items.push({
        name: "",
        parentId: null,
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
</style>